/* Header
 *
 *  Props:
 *    title: item title
 *    url: for sharing
 */
import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ShareBar from "./share-bar"
import CartButton from "./cart-button"
import HeaderButton from "./header-button"

import useUserPreferences from "../hooks/use-user-preferences"

const Sticky = styled.div`
  position: sticky;
  top: 0px;
  z-index: 2;
`

const StyledHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  font-family: sans-serif;
  background-color: var(--darkBackgroundColor);
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 10px;
`

export default function Header(props) {

  const purchase = useUserPreferences().purchase
  const breakpoints = useBreakpoint()

  return (
    <Sticky>
      <StyledHeader data-qa={"Header"}>
        <HeaderButton
          aria-label={"Home"}
          onClick={async () => await navigate("/")}
          data-qa={"HomeButton"}
          id="HeaderHomeButton"
        >
          <i style={{lineHeight: "0"}} className="fas fa-home"></i>
        </HeaderButton>
        <ShareBar
          title={props.title}
          url={props.url}
          showText={!purchase || breakpoints.smallOrLarger}
        />
        {purchase && <CartButton />}
      </StyledHeader>
    </Sticky>
  )
}
