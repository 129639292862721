/*
 * ShareBar
 * props:
 *   url: the url to share
 *   title: the title of the website to share
 *   showText: (boolean, default true) show the "Share" text
 *
 * https://www.npmjs.com/package/react-share
 */
import React from "react"
import styled from "styled-components"
import useSiteStyles from "../hooks/use-site-styles"

import {
  EmailShareButton,
  EmailIcon,

  FacebookShareButton,
  FacebookIcon,

  TwitterShareButton,
  TwitterIcon,

  WhatsappShareButton,
  WhatsappIcon,

} from "react-share"

const StyledShareBar = styled.div`
  display: inline-grid;
  grid-template-columns: auto repeat(4, min-content);
  column-gap: 6px;
  text-align: right;
  align-items: right;
  background-color: var(--darkBackgroundColor);

  // Hack because weird icons
  .share-icon {
    margin-top: 4px;
  }
`

const ShareHeader = styled.div`
  align-self: center;
  font-family: sans-serif;
`

export default function ShareBar(props) {

  const url = props.url,
    title = props.title;

  const siteStyles = useSiteStyles()
  const iconProps = {
    size: 32,
    round: true,
    bgStyle: { fill: siteStyles.colors.shareIcon.toString() }
  }

  return (
    <StyledShareBar>
      { props.showText &&
        <ShareHeader>Share</ShareHeader>
      }

      <EmailShareButton
        className={"share-icon"}
        url={url}
        subject={title}
        body={title}
        data-qa={"ShareSiteByEmailButton"}
      >
        <EmailIcon {...iconProps} />
      </EmailShareButton>

      <FacebookShareButton
        className={"share-icon"}
        url={url}
        quote={title}
        data-qa={"ShareSiteByFacebookButton"}
      >
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>

      <TwitterShareButton
        className={"share-icon"}
        url={url}
        title={title}
        data-qa={"ShareSiteByTwitterButton"}
      >
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>

      <WhatsappShareButton
        className={"share-icon"}
        url={url}
        title={title}
        data-qa={"ShareSiteByWhatsappButton"}
      >
        <WhatsappIcon {...iconProps} /> 
      </WhatsappShareButton>
    </StyledShareBar>
  )
}

ShareBar.defaultProps = {
  showText: true,
}
