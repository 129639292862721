/* HeaderButton
 */

import React from "react"
import styled from "styled-components"
import AccentedButton from "./accented-button"

const StyledHeaderButton = styled(AccentedButton)`
  height: 32px;
`

const ButtonText = styled.div`
  font-size: medium;
  text-transform: none;
  letter-spacing: normal;
  line-height: 0;

  text-decoration: none;
`

export default function HeaderButton(props) {
  return (
    <StyledHeaderButton {...props} data-qa={props["data-qa"] || "HeaderButton"}>
      <ButtonText>
        {props.children}
      </ButtonText>
    </StyledHeaderButton>
  )
}
