/* CartButton
 */

import React from "react"
import { navigate } from "gatsby"
import useShoppingCart from "../hooks/use-shopping-cart"
import HeaderButton from "./header-button"

export default function CartButton(props) {
  const cart = useShoppingCart()
  const cartCount = cart.getQuantity()
  return (
    <HeaderButton
      aria-label={"Cart"}
      onClick={async () => await navigate("/cart/")}
      data-qa={props["data-qa"] || "CartButton"}
    >
      <i style={{lineHeight: "0"}} className="fas fa-shopping-cart"></i>
      {cartCount > 0 && (
        <span data-qa={"CartCount"}>
          {` (${cartCount})`}
        </span>
      )}
    </HeaderButton>
  )
}
